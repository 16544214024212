<template>
  <div class="cause">
    <!-- <a
      href="https://www.kkyamasiro.co.jp/brand/extra-shield/"
      class="sank-uikiki"
      target="_blank"
    >
      <img src="@/assets/SANK-UIKIKI.png" alt="" />
    </a> -->
    <div class="text-container">
      <div class="text_item">
        <div class="border"></div>
        <div class="title">二輪事業</div>
        <div
          style="
            border: 0.1px solid #fff;
            width: 100px;
            margin-bottom: 4px;
            margin-top: 2px;
          "
        ></div>
        <div style="border: 0.1px solid #fff; width: 100px; margin-bottom: 20px"></div>
        <p style="margin-bottom: 20px">
          当社は、オートバイヘルメット製品の販売やアフターサービスの専門会社であり、主にバイク愛好家のお客様向けに最高品質の商品を提供しております。。
        </p>
        <p>
          お客様が製品を選択する際に、最もお客様に合う製品を提供し、日々の製品メンテナンスの仕方も丁寧にご案内いたします。
        </p>
      </div>

      <div class="text_item">
        <div class="border"></div>
        <div class="title">四輪事業</div>
        <div
          style="
            border: 0.1px solid #fff;
            width: 100px;
            margin-bottom: 4px;
            margin-top: 2px;
          "
        ></div>
        <div style="border: 0.1px solid #fff; width: 100px; margin-bottom: 20px"></div>
        <p style="margin-bottom: 20px">
          四輪事業は、自動車に関わる用品・部品を整備工場に供給販売する部門です。
        </p>
      </div>

      <div class="text_item">
        <div class="border"></div>
        <div style="display: flex" class="img-container">
          <img src="@/assets/factory-photo1.jpg" alt="" style="margin-left: 0" />
          <img src="@/assets/factory-photo2.jpg" alt="" />
          <img src="@/assets/factory-photo3.jpg" alt="" style="margin-right: 0" />
        </div>
      </div>
      <div class="botImg">
        <img src="@/assets/between-logo.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileCause",
};
</script>

<style lang="scss" scoped>
.text-container {
  margin: 10px auto;
  .text_item {
    text-align: left;
    .border {
      border-top: 1px dashed #3b3b3b;
      /* 设置顶部边框为点线 */
      border-radius: 0.5px;
      /* 圆角边框 */
      background-image: linear-gradient(to right, #fff 50%, #3b3b3b 50%);
      /* 创建点线效果的背景图像 */
      background-size: 10px 10px;
      /* 背景图像大小 */
      height: 1px;
      /* 线的高度 */
      width: 100%;
      /* 线的宽度 */
      text-align: center;
      /* 文本居中 */
      background-repeat: repeat-x;
      /* 背景图像横向重复 */
      margin-bottom: 20px;
    }
    .title {
      width: 200px;
      text-align: left;
      font-size: 16px;
      letter-spacing: 4px;
    }
    p {
      letter-spacing: 2px;
      font-size: 16px;
      line-height: 20px;
    }
    .img-container {
      margin: 10px 0;
      img {
        margin: auto;
        width: 32%;
        height: 80px;
        box-shadow: 1px 0 2px 2px rgb(0, 0, 0), 3px 2px 3px 2px rgb(255, 255, 255, 0.7);
      }
    }
  }
  .botImg {
    width: 100%;
    margin-top: 10px;
    img {
      display: block;
      width: 100%;
      box-shadow: 1px 0 3px 4px rgb(0, 0, 0), 3px 2px 3px 4px rgb(255, 255, 255, 0.7);
    }
  }
}
</style>
